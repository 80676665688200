@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

@mixin colors($type) {
    $bg-color: map-get-deep($opds, 'color', 'background', 'button', $type);
    $highlight-border-color: map-get-deep(
        $opds,
        'color',
        'border',
        'button',
        $type
    );
    background-color: $bg-color;
    border-color: map-get-deep($opds, 'color', 'border', 'button', $type);
    color: map-get-deep($opds, 'color', 'text', 'button', $type);

    &:hover,
    &:focus {
        $alpha: if(
            $type == 'primary' or $type == 'finish',
            $opds-misc-alpha-hover-colored,
            $opds-misc-alpha-hover-default
        );
        background-color: darken-relative($bg-color, $alpha);
        border-color: $highlight-border-color;
    }

    &:focus {
        outline-color: $opds-color-outline-focus;
        outline-style: $opds-style-outline-focus;
        outline-width: $opds-width-outline-focus;
    }

    // Firefox: hide text/icon outline when focused
    &::-moz-focus-inner {
        border: 0;
    }

    &.ds-button--non-interactive:focus {
        background-color: $bg-color;
        border-color: map-get-deep($opds, 'color', 'border', 'button', $type);
    }

    &:active {
        $alpha: if(
            $type == 'primary' or $type == 'finish',
            $opds-misc-alpha-active-colored,
            $opds-misc-alpha-active-default
        );
        background-color: darken-relative($bg-color, $alpha);
        border-color: $highlight-border-color;
        border-style: solid;
    }
}

.ds-button {
    @include colors('default');
    align-items: center;
    border-radius: 50vh;
    border-style: solid;
    border-width: $opds-border-width-default;
    cursor: pointer;
    display: inline-flex;
    font-family: inherit;
    font-size: $opds-font-size-medium;
    font-weight: $opds-font-weight-medium;
    justify-content: center;
    line-height: $opds-line-height-default;
    margin: 0;
    overflow: hidden;
    padding: bordered($opds-space-inset-button-default);
    text-decoration: none;
    white-space: nowrap;
    width: gu(62);

    @media screen and (min-width: $opds-breakpoint-sm-min) {
        min-width: gu(40);
        width: auto;
    }

    // overrides <a> element style
    &#{&} {
        background-image: none;
    }

    &#{&}--primary {
        @include colors('primary');
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-weight: $opds-font-weight-bold;
    }

    &#{&}--alternate {
        @include colors('alternate');
    }

    &#{&}--finish {
        @include colors('finish');
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-weight: $opds-font-weight-bold;
    }

    &#{&}:disabled,
    &#{&}--disabled {
        background-color: $opds-color-background-button-disabled;
        border-color: $opds-color-border-button-disabled;
        color: $opds-color-text-disabled;
        text-decoration: $opds-text-decoration-disabled;
    }

    &:disabled,
    &--disabled,
    &--non-interactive {
        cursor: default;
        pointer-events: none;
    }

    &--wide {
        width: gu(62);
    }

    &--large,
    &--hero {
        padding: bordered($opds-space-inset-button-large);

        .ds-button__icon,
        .ds-button__loading {
            margin-bottom: ($opds-line-height-default - $opds-size-icon-large) /
                2;
            margin-top: ($opds-line-height-default - $opds-size-icon-large) / 2;
        }
    }

    @media only screen and (min-width: $opds-breakpoint-sm-min) {
        &--hero {
            font-size: $opds-font-size-large;
            line-height: $opds-line-height-large;
            padding: bordered($opds-space-inset-button-hero);

            .ds-button__icon,
            .ds-button__loading {
                margin-bottom: ($opds-line-height-large - $opds-size-icon-large) /
                    2;
                margin-top: ($opds-line-height-large - $opds-size-icon-large) /
                    2;
            }
        }
    }

    &--icon-left {
        .ds-button__icon {
            margin-left: $opds-space-inline-icon-button-outer;
            margin-right: $opds-space-inline-icon-button-inner;
        }
    }

    &--icon-right {
        flex-direction: row-reverse;

        .ds-button__icon {
            margin-left: $opds-space-inline-icon-button-inner;
            margin-right: $opds-space-inline-icon-button-outer;
        }
    }

    &--compact {
        border-radius: $opds-border-radius-button-small;
        font-size: $opds-font-size-micro;
        letter-spacing: $opds-letter-spacing-caps;
        line-height: $opds-line-height-micro;
        min-width: gu(30);
        padding: bordered($opds-space-inset-button-compact);
        text-transform: uppercase;
        width: auto;

        .ds-button__icon,
        .ds-button__loading {
            margin-bottom: ($opds-line-height-micro - $opds-size-icon-small) / 2;
            margin-top: ($opds-line-height-micro - $opds-size-icon-small) / 2;
        }
    }

    &--minified {
        min-width: auto;
        padding: bordered(nth($opds-space-inset-button-default, 1));
        width: auto;

        &.ds-button--large,
        &.ds-button--hero {
            padding: bordered(nth($opds-space-inset-button-large, 1));

            .ds-button__icon {
                margin: ($opds-line-height-default - $opds-size-icon-large) / 2;
            }
        }

        &.ds-button--hero {
            @media only screen and (min-width: $opds-breakpoint-sm-min) {
                padding: bordered(nth($opds-space-inset-button-hero, 1));

                .ds-button__icon {
                    margin: ($opds-line-height-large - $opds-size-icon-large) /
                        2;
                }
            }
        }

        &.ds-button--compact {
            padding: bordered(nth($opds-space-inset-button-compact, 1));
            width: auto;

            .ds-button__icon {
                margin: ($opds-line-height-micro - $opds-size-icon-small) / 2;
            }
        }
    }

    &--ghost {
        &,
        &:disabled {
            background-color: transparent;
        }

        &:hover,
        &:focus {
            background-color: rgba(
                $opds-color-palette-black,
                $opds-misc-alpha-hover-default / 100%
            );
        }

        &,
        &:hover,
        &:focus,
        &:active,
        &#{&}:disabled {
            border-color: transparent;
        }

        &.ds-button--non-interactive:focus {
            background: transparent;
        }

        &:active {
            background-color: rgba(
                $opds-color-palette-black,
                $opds-misc-alpha-active-default / 100%
            );
        }
    }
}
