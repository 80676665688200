@import '@op/opds-tokens/lib/web/op/functions';
@import '@op/opds-tokens/lib/web/op/tokens';

$step-fill-scale: 0.83;
$step-active-scale: 1.2;
$step-active-center-scale: 0.6;
$animation-time: 0.3s;

.ds-progress-tracker {
    display: flex;
    margin: $opds-space-xxxs;

    &__step {
        align-items: center;
        display: flex;
        position: relative;
        width: 100%;
    }

    &__step__indicator {
        display: block;
        height: gu(4);
        transform: scale(1);
        transform-origin: center center;
        transition: all $animation-time;
        width: gu(4);
        z-index: 2;

        &::before {
            background-color: $opds-color-background-progressbar-track;
            border-radius: 50%;
            content: '';
            height: 100%;
            position: absolute;
            transform: scale($step-active-center-scale);
            transform-origin: center center;
            transition: transform $animation-time,
                background-color $animation-time;
            width: 100%;
            z-index: 2;
        }

        &::after {
            background: $opds-color-background-progressbar-track;
            border: $opds-border-width-default solid
                $opds-color-border-progressbar-track-bottom;
            border-top-color: $opds-color-border-progressbar-track-default;
            border-radius: 50%;
            content: '';
            display: block;
            height: 100%;
            transform: scale(1);
            transform-origin: center center;
            transition: transform $animation-time;
            width: 100%;
        }
    }

    &__step__progress {
        background-color: $opds-color-background-progressbar-track;
        border-bottom: $opds-border-width-default solid
            $opds-color-border-progressbar-track-bottom;
        border-top: $opds-border-width-default solid
            $opds-color-border-progressbar-track-default;
        height: gu(1);
        position: absolute;
        transition: border-color $animation-time linear;
        width: calc(100% + #{$opds-size-hairline});
        z-index: 3;

        &::before {
            background-color: $opds-color-indicator-default;
            content: '';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            transition: width $animation-time linear;
            width: 0;
        }

        &::after {
            background-color: $opds-color-background-progressbar-track;
            border-radius: 50%;
            content: '';
            height: gu(4);
            position: absolute;
            top: -((gu(4) / (gu(4) * $step-active-scale) * $step-fill-scale) *
                        1rem); // 1rem to display the bar correctly with the circles
            transform: scale($step-fill-scale);
            width: gu(4);
            z-index: 3;
        }
    }

    &__step--current > &__step__indicator {
        transform: scale($step-active-scale);
        z-index: 4;

        &::before {
            background: $opds-color-indicator-default;
            transition-delay: $animation-time;
        }

        &::after {
            border-color: $opds-color-indicator-default;
            border-width: $opds-border-radius-small;
            transform: scale($step-active-scale);
        }
    }

    &__step--complete > &__step__indicator {
        z-index: 4;

        &::before {
            background: $opds-color-indicator-default;
            transform: scale(1);
        }

        &::after {
            border-color: $opds-color-indicator-default;
        }
    }

    &__step--complete > &__step__progress {
        border-color: $opds-color-indicator-default;

        &::before {
            width: 100%;
        }
    }

    &__step--skipped {
        .ds-progress-tracker__step__indicator,
        .ds-progress-tracker__step__progress {
            transition-duration: 0s;

            &::after,
            &::before {
                transition-duration: 0s;
            }
        }
    }

    &__step--anim-progress-only > &__step__progress {
        transition-duration: $animation-time;

        &::before {
            transition-duration: $animation-time;
        }
    }

    &__step:last-child {
        width: gu(4);

        .ds-progress-tracker__step__progress {
            display: none;
        }
    }
}
