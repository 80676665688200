@import '@op/opds-tokens/lib/web/op/tokens';

.ds-counter {
    color: $opds-color-text-secondary;
    font-size: $opds-font-size-small;
    line-height: $opds-line-height-small;
    margin-top: $opds-space-xxs;
    text-align: right;

    &--hero {
        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            font-size: $opds-font-size-default;
            line-height: $opds-line-height-default;
        }
    }
}
