@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

.ds-info-box-reference {
    &.ds-button--minified.ds-button--compact {
        .ds-button__icon {
            margin: ($opds-line-height-micro - $opds-size-icon-default) / 2;
        }
    }
}

.ds-info-box {
    $arrow-size: $opds-space-xxs;
    $arrow-margin: $opds-space-xs;
    $box-margin: $opds-space-xs;
    $border-color: $opds-color-background-default;
    background: $opds-color-background-default;
    border-radius: $opds-border-radius-small;
    color: $opds-color-text-label-default;
    display: none;
    filter: $opds-elevation-filter-4;
    font-size: $opds-font-size-small;
    font-weight: $opds-font-weight-default;
    line-height: $opds-line-height-small;
    max-width: 26rem;
    padding: $opds-space-inset-default;
    will-change: filter; // Fixes disappearing borders in Safari
    z-index: 200;

    &:focus {
        outline-color: $opds-color-outline-focus;
        outline-style: $opds-style-outline-focus;
        outline-width: $opds-width-outline-focus;
    }

    &--box-shadow {
        box-shadow: $opds-elevation-tooltip;
        filter: none;
    }

    &--flat {
        display: block;
        position: relative;
    }

    &--fluid {
        max-width: none;
        padding: 0;
    }

    &--open {
        align-items: flex-start;
        display: flex;
    }

    &--enter {
        opacity: 0;
    }

    &--enter-active {
        opacity: 1;
        transition: opacity 250ms;
    }

    &-exit {
        display: flex;
        opacity: 1;
    }

    &--exit-active {
        display: flex;
        opacity: 0;
        transition: opacity 250ms;
    }

    &__close {
        margin-bottom: -($opds-space-xs / 2);
        margin-left: $opds-space-small - ($opds-space-xs / 2);
        margin-right: -($opds-space-xs / 2);
        margin-top: -($opds-space-xs / 2);
        min-width: 3.2rem;
    }

    [data-popper-arrow] {
        border: $arrow-size solid transparent;
        height: 0;
        position: absolute;
        width: 0;
    }

    &[data-popper-placement|='top'] {
        margin-bottom: $box-margin !important;

        [data-popper-arrow] {
            border-bottom: 0;
            border-top-color: $border-color;
            bottom: -$arrow-size;
        }
    }

    &[data-popper-placement|='right'] {
        margin-left: $box-margin !important;

        [data-popper-arrow] {
            border-left: 0;
            border-right-color: $border-color;
            left: -$arrow-size;
        }
    }

    &[data-popper-placement|='bottom'] {
        margin-top: $box-margin !important;

        [data-popper-arrow] {
            border-bottom-color: $border-color;
            border-top: 0;
            top: -$arrow-size;
        }
    }

    &[data-popper-placement|='left'] {
        margin-right: $box-margin !important;

        [data-popper-arrow] {
            border-left-color: $border-color;
            border-right: 0;
            right: -$arrow-size;
        }
    }

    &[data-popper-placement='left'],
    &[data-popper-placement='right'] {
        [data-popper-arrow] {
            bottom: auto;
            top: calc(50% - #{$arrow-size});
        }
    }

    &[data-popper-placement='left-start'],
    &[data-popper-placement='right-start'] {
        [data-popper-arrow] {
            bottom: 0;
            top: auto;
        }
    }

    &[data-popper-placement='left-end'],
    &[data-popper-placement='right-end'] {
        [data-popper-arrow] {
            bottom: auto;
            top: 0;
        }
    }

    &[data-popper-placement='top'],
    &[data-popper-placement='bottom'] {
        [data-popper-arrow] {
            left: calc(50% - #{$arrow-size});
            right: auto;
        }
    }

    &[data-popper-placement='top-start'],
    &[data-popper-placement='bottom-start'] {
        [data-popper-arrow] {
            left: auto;
            right: 0;
        }
    }

    &[data-popper-placement='top-end'],
    &[data-popper-placement='bottom-end'] {
        [data-popper-arrow] {
            left: 0;
            right: auto;
        }
    }
}
