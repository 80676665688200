@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';
@import '@op/opds-tokens/lib/web/op/icon/navigation';

// These and other similar line-height/padding changes are because special
// characters were being cut off https://oppjira.eficode.com/browse/OPDS-2338
$line-height-default: 3.2rem;
$line-height-hero: 3.6rem;

.ds-select {
    appearance: none;
    background-color: $opds-color-background-input-default;
    background-image: opds-icon-navigation-expand($opds-color-icon-action);
    background-position: right bordered($opds-space-xs) center;
    background-repeat: no-repeat;
    background-size: $opds-size-icon-default;
    border-color: $opds-color-border-input-default;
    border-radius: $opds-border-radius-default;
    border-width: $opds-border-width-default;
    color: $opds-color-text-large;
    font-family: inherit;
    font-size: $opds-font-size-medium;
    font-weight: $opds-font-weight-default;
    line-height: $line-height-default;
    padding: bordered($opds-space-xxxs) bordered($opds-space-xs);
    padding-right: bordered($opds-size-icon-default + 2 * $opds-space-xs);
    width: 100%;

    &:disabled {
        background-image: opds-icon-navigation-expand(
            $opds-color-icon-disabled
        );
    }

    &:hover,
    &:focus {
        background-color: darken-relative(
            $opds-color-background-input-default,
            $opds-misc-alpha-hover-default
        );
        border-color: $opds-color-border-input-hover;
    }

    &:active {
        background-color: darken-relative(
            $opds-color-background-input-default,
            $opds-misc-alpha-active-default
        );
    }

    &:focus {
        outline-color: $opds-color-outline-input-focus;
        outline-style: $opds-style-outline-input-focus;
        outline-width: $opds-width-outline-input-focus;
    }

    &:disabled {
        border-color: $opds-color-border-disabled;
        color: $opds-color-text-disabled;
        pointer-events: none;
        text-decoration: $opds-text-decoration-disabled;
    }

    &--invalid {
        background-color: $opds-color-background-input-invalid;
        border-color: $opds-color-border-input-invalid;
        box-shadow: 0 0 gu(1)
            rgba(
                $opds-color-indicator-error,
                $opds-misc-alpha-darken-600 / 100%
            );

        &:hover,
        &:focus {
            box-shadow: 0 0 gu(2)
                rgba(
                    $opds-color-indicator-error,
                    $opds-misc-alpha-darken-400 / 100%
                );
        }
    }

    &--small {
        background-position: right bordered($opds-space-xxs) center;
        background-size: $opds-size-icon-small;
        color: $opds-color-text-default;
        font-size: $opds-font-size-default;
        line-height: $opds-line-height-default;
        padding: bordered($opds-space-inset-input-small);
        padding-right: bordered($opds-size-icon-small + 2 * $opds-space-xxs);
    }

    &--large,
    &--hero {
        background-position: right bordered($opds-space-xs) center;
        background-size: $opds-size-icon-large;
        padding: bordered($opds-space-xxs) bordered($opds-space-small);
        padding-right: bordered($opds-size-icon-large + 2 * $opds-space-xs);
    }

    @media only screen and (min-width: $opds-breakpoint-sm-min) {
        &--hero {
            background-position: right bordered($opds-space-small) center;
            background-size: $opds-size-icon-large;
            font-size: $opds-font-size-large;
            line-height: $line-height-hero;
            padding: bordered($opds-space-xs) bordered($opds-space-default);
            padding-right: bordered(
                $opds-size-icon-large + 2 * $opds-space-small
            );
        }
    }
}
