@import '@op/opds-tokens/lib/web/op/functions';
@import '@op/opds-tokens/lib/web/op/tokens';

/**
 * Inputs & button
 */
.ds-date-picker {
    align-items: flex-end;
    display: flex;
    position: relative;

    &__input {
        max-width: 50%;

        &--single {
            max-width: 100%;
        }
    }

    &__button {
        display: inline-flex;
        margin-left: $opds-space-xxs;
        overflow: visible;
    }

    &__button.ds-button--hero,
    &__button.ds-button--large {
        margin-left: $opds-space-xs;
    }

    &__input-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        width: 100%;
    }

    &__input-wrapper__divider {
        align-items: center;
        display: flex;
        font-size: $opds-font-size-small;
        font-weight: $opds-font-weight-default;
        justify-content: center;
        line-height: 0;
        width: $opds-space-xl;
    }
}

/**
 * Popup
 */

$single-date-picker-width: gu(11) * 7 + 2 * $opds-space-xs; // 44px * 7 + 2 * 12px = 332px

.ds-date-picker__popup {
    .DayPicker__horizontal {
        /* stylelint-disable */
        &,
        > div > div,
        .DayPicker_weekHeader,
        .CalendarMonth {
            width: $single-date-picker-width !important;
        }
        /* stylelint-enable */

        .CalendarMonthGrid__horizontal {
            left: 0;
        }

        .DayPicker_weekHeaders,
        .DayPicker_focusRegion {
            margin-left: 0;
        }
    }

    // React-dates calculate height based on element clientHeight (for
    // .CalendarMonth_caption) and it fails with display: none.
    &:not(.ds-info-box--open) {
        display: block !important;
        opacity: 0;
        pointer-events: none;
    }

    &:not(.ds-info-box--open) .CalendarMonth_table {
        display: none;
    }

    .ds-date-picker__nav {
        align-items: center;
        border-radius: 50vh;
        border-style: solid;
        border-width: $opds-border-width-default;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        left: $opds-space-xxs;
        overflow: hidden;
        padding: bordered(nth($opds-space-inset-button-large, 1));
        position: absolute;
        top: $opds-space-xxs;

        &--next {
            left: auto;
            right: $opds-space-xxs;
        }

        &:focus {
            outline-color: $opds-color-outline-focus;
            outline-style: $opds-style-outline-focus;
            outline-width: $opds-width-outline-focus;
        }

        &,
        &:disabled {
            background-color: transparent;
        }

        &:hover,
        &:focus {
            background-color: rgba(
                $opds-color-palette-black,
                $opds-misc-alpha-hover-default / 100%
            );
        }

        &,
        &:hover,
        &:focus,
        &:active,
        &:disabled {
            border-color: transparent;
        }

        &:active {
            background-color: rgba(
                $opds-color-palette-black,
                $opds-misc-alpha-active-default / 100%
            );
        }

        svg {
            height: $opds-size-icon-large;
            margin: ($opds-line-height-default - $opds-size-icon-large) / 2;
            width: $opds-size-icon-large;
        }

        path {
            fill: $opds-color-icon-action;
        }
    }
}

.ds-date-picker__popup--range .DayPicker__horizontal {
    &,
    .DayPicker_focusRegion {
        width: $single-date-picker-width * 2 + $opds-border-width-default !important;
    }

    .DayPicker_weekHeader:not(:first-child) {
        left: $single-date-picker-width + $opds-border-width-default !important;
    }

    .CalendarMonthGrid::after {
        background-color: $opds-color-border-default;
        bottom: $opds-space-xs * -1;
        content: '';
        left: $single-date-picker-width;
        position: absolute;
        top: $opds-space-xs;
        width: $opds-border-width-default;
    }
}

.ds-date-picker__popup .DayPicker {
    overflow-x: hidden;

    // Reset react-dates styles.
    &,
    .CalendarMonth,
    .CalendarMonthGrid {
        background-color: transparent;
    }

    &_transitionContainer {
        transition: none;
        width: 100% !important; // Override automatically set pixel value width
    }

    .CalendarMonth_table {
        border-collapse: unset;
        width: 100%;
    }

    .CalendarMonth_caption {
        color: $opds-color-text-large;
        font-size: $opds-font-size-default;
        font-weight: $opds-font-weight-bold;
        line-height: $opds-line-height-default;
        margin-bottom: $opds-line-height-micro + $opds-space-xxs * 2 +
            $opds-space-xxs;
        padding: $opds-space-small + $opds-space-xxxs 0;
        padding-bottom: $opds-space-small + $opds-space-xxxs;
    }

    &Navigation_button {
        left: $opds-space-xxs;
        padding: 0;
        position: absolute;
        top: $opds-space-xxs;

        + div {
            left: auto;
            right: $opds-space-xxs;
        }
    }

    &_weekHeader {
        background: $opds-color-background-tertiary;
        top: $opds-space-small + $opds-size-icon-large + $opds-space-small;
    }

    &_weekHeader_ul {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &_weekHeader_li {
        margin: $opds-space-xxxs $opds-space-xxxs/2;
        width: 14.285% !important; // Override automatic pixel value for a single day

        &::before {
            content: none;
        }

        small {
            color: $opds-color-text-label-default;
            font-size: $opds-font-size-micro;
            font-weight: $opds-font-weight-medium;
            line-height: $opds-line-height-micro;
        }
    }

    // Vertical
    &_transitionContainer__vertical {
        height: 48rem !important;

        /* stylelint-disable */
        // Super dirty selector :/
        .CalendarMonthGrid > div + div + div .CalendarMonth_caption {
            margin-bottom: 0;
        }
        /* stylelint-enable */

        .CalendarMonth_table {
            margin-bottom: 0;
        }
    }

    &_weekHeader__vertical {
        width: 100%;
    }

    .CalendarDay {
        border: solid $opds-space-xxxs/2 $opds-color-background-default;
        color: $opds-color-text-menu;
        font-size: $opds-font-size-small;
        font-weight: $opds-font-weight-medium;
        padding: 0;
        position: relative;
        vertical-align: middle;

        &__today {
            text-decoration: underline;
        }
    }

    .CalendarDay__default:hover,
    .CalendarDay__default:focus,
    .CalendarDay__selected,
    .CalendarDay__selected_span,
    .CalendarDay__hovered_span {
        background: $opds-color-background-default;

        &::after {
            background-color: $opds-color-indicator-default;
            bottom: 0;
            content: '';
            height: gu(1);
            left: $opds-space-xxxs * -1 * 0.5; // Stretch the highlight to touch the previous day.
            pointer-events: none;
            position: absolute;
            right: $opds-space-xxxs * -1 * 0.5; // Stretch the highlight to touch the next day.
        }

        &:active::after {
            height: gu(0.75);
        }
    }

    .CalendarDay__default {
        &:focus {
            background-color: darken-relative(
                $opds-color-background-default,
                $opds-misc-alpha-hover-default
            );
            outline-color: $opds-color-outline-focus;
            outline-offset: $opds-space-xxxs * -1 * 0.5;
            outline-style: $opds-style-outline-focus;
            outline-width: $opds-width-outline-focus;
        }

        &:hover {
            background-color: darken-relative(
                $opds-color-background-default,
                $opds-misc-alpha-hover-default
            );
        }

        &:active {
            background-color: darken-relative(
                $opds-color-background-default,
                $opds-misc-alpha-active-default
            );
        }
    }

    .CalendarDay__defaultCursor {
        pointer-events: none;
    }

    .CalendarDay__selected:not(.CalendarDay__selected_start):not(.CalendarDay__selected_end) {
        &::after {
            left: 0;
            right: 0;
        }
    }

    .CalendarDay__default:not(.CalendarDay__selected_start):not(.CalendarDay__selected_end):not(.CalendarDay__selected_span),
    .CalendarDay__default:not(.CalendarDay__selected_start):not(.CalendarDay__selected_end):not(.CalendarDay__selected_span) {
        &:focus::after,
        &:hover::after {
            left: 0;
            right: 0;
        }
    }

    .CalendarDay__selected_start,
    .CalendarDay.CalendarDay__firstDayOfWeek,
    .CalendarDay__default.CalendarDay__selected_start:hover {
        &::after {
            left: 0;
        }
    }

    .CalendarDay__selected_end,
    .CalendarDay.CalendarDay__lastDayOfWeek,
    .CalendarDay__after_hovered_start:not(.CalendarDay__selected_span),
    .CalendarDay__hovered_span:hover,
    .CalendarDay__default.CalendarDay__selected_end:hover {
        &::after {
            right: 0;
        }
    }

    .CalendarDay__selected_start,
    .CalendarDay__hovered_span {
        /* stylelint-disable */
        & + .CalendarDay__default:hover::after {
            left: $opds-space-xxxs * -1 * 0.5 !important;
        }
        /* stylelint-enable */
    }

    .CalendarDay__blocked_calendar,
    .CalendarDay__blocked_out_of_range {
        background-color: transparent;
        color: $opds-color-text-disabled;
        text-decoration: $opds-text-decoration-disabled;

        &:hover {
            background-color: transparent;
        }

        &:not(.CalendarDay__selected_span)::after,
        &:not(.CalendarDay__selected_span):hover::after {
            content: none;
        }
    }
}
