@import '../styles/svg-to-url';
@import 'selectable';

@function checkmark($color) {
    // Returns a custom bolder variant of checkmark icon used only in checkbox
    @return svg-to-url(
        '<?xml version="1.0" encoding="UTF-8"?><svg width="32" height="32" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><g stroke="#{$color}" stroke-width="1"><path d="M25.1472415,8.30515975 C25.3350877,8.07462122 25.5970062,7.93999059 25.8707095,7.90762373 C26.1561526,7.87386856 26.4544133,7.95133806 26.6948402,8.14724152 C26.933715,8.34188017 27.0696204,8.61604477 27.095486,8.90044375 C27.120617,9.17676593 27.0418695,9.46274946 26.8527585,9.69484025 L15.9620784,23 C15.7492476,23.2612015 15.368567,23.3904312 14.9843812,23.3876891 C14.608178,23.3850039 14.2286138,23.2557742 14,23 L7.1958392,15.7505501 C6.98959253,15.5295715 6.89190779,15.246221 6.90051896,14.9662131 C6.90921465,14.6834572 7.02630276,14.4041099 7.24944992,14.1958392 C7.47313923,13.9870625 7.76074007,13.8895275 8.04408983,13.9008838 C8.32331914,13.912075 8.59842012,14.0290135 8.8041608,14.2494499 L15.0017255,20.9840698 L25.1472415,8.30515975 Z" fill="#{$color}" /></g></svg>'
    );
}

.ds-checkbox {
    @include selectable;

    :checked + ::before {
        background-image: checkmark($opds-color-indicator-default);
        background-position: center;
        background-size: calc(100% + 2 * #{$opds-border-width-default});
    }

    &--disabled :checked + ::before {
        background-image: checkmark($opds-color-indicator-passive);
    }
}
