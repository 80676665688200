@import '@op/opds-tokens/lib/web/op/functions';
@import '@op/opds-tokens/lib/web/op/tokens';

.ds-more-less {
    display: flex;
    margin-bottom: $opds-space-default;

    button:not(:first-child) {
        margin-left: $opds-space-small;
    }

    a:not(:first-child) {
        margin-left: $opds-space-xs;

        &::before {
            background-image: linear-gradient(
                to bottom,
                $opds-color-border-default 0,
                $opds-color-border-default gu(5)
            );
            background-position: center right;
            background-repeat: no-repeat;
            content: '';
            height: gu(5);
            position: absolute;
            width: $opds-border-width-default;
        }

        span {
            margin-left: $opds-space-small;
        }
    }
}
