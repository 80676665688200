@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

.ds-expander-list {
    overflow-anchor: none;
}

.ds-expander {
    border-bottom: $opds-border-width-default solid $opds-color-border-default;
    border-top: $opds-border-width-default solid $opds-color-border-default;
    transition: height 0.25s ease;

    &--embedded {
        border-top-color: transparent;
    }

    &--open {
        border-bottom: 0;
    }

    // Nested expander border rules
    &--embedded.ds-expander.ds-expander--open {
        border-bottom: $opds-border-width-default solid
            $opds-color-border-default;
    }

    // Nested expander border rules
    &--embedded.ds-expander--open {
        border-bottom: $opds-border-width-default solid
            $opds-color-border-default;
    }

    + .ds-expander {
        border-top-color: transparent;
    }

    &--embedded.ds-expander:first-child {
        border-top-color: transparent;
    }

    &--embedded.ds-expander:last-child {
        border-bottom-color: transparent;
    }

    &--disabled {
        border-bottom: $opds-border-width-default solid
            $opds-color-border-disabled;
        border-top: $opds-border-width-default solid $opds-color-border-disabled;
    }

    &__header {
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        overflow-x: hidden;

        &:hover,
        &:focus,
        &:focus-within {
            background-color: rgba(
                $opds-color-palette-black,
                $opds-misc-alpha-hover-default / 100%
            );
        }

        &:active {
            background-color: rgba(
                $opds-color-palette-black,
                $opds-misc-alpha-active-default / 100%
            );
        }

        &:focus,
        &:focus-within {
            outline: $opds-color-outline-focus $opds-width-outline-focus
                $opds-style-outline-focus;
            outline-offset: 0;
        }

        .ds-expander--disabled & {
            cursor: default;

            &:focus,
            &:focus-within {
                background: transparent;
                outline: none;
            }

            &:hover {
                background-color: transparent;
            }

            &:active {
                background-color: transparent;
            }
        }
    }

    &--indented > .ds-expander__header {
        padding: 0 $opds-space-small;

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            padding: 0 $opds-space-default;
        }
    }

    & &__title {
        background: none;
        color: $opds-color-text-default;
        display: block;
        font-size: $opds-font-size-default;
        font-weight: $opds-font-weight-default;
        letter-spacing: $opds-letter-spacing-default;
        line-height: $opds-line-height-default;
        margin-right: $opds-space-xs;
        padding: bordered($opds-space-default) 0;
        width: 100%;
    }

    &--open > .ds-expander__header > &__title {
        font-weight: $opds-font-weight-medium;
    }

    &--small > .ds-expander__header > &__title {
        padding: bordered($opds-space-small) 0;
    }

    &--tiny > .ds-expander__header > &__title {
        padding: bordered($opds-space-xs) 0;
    }

    .ds-expander--disabled &__title {
        color: $opds-color-text-disabled;
        text-decoration: $opds-text-decoration-disabled;
    }

    &__header-action {
        background: transparent;
        border: 0;
        line-height: 0;
        margin: 0;
        padding: 0;
        pointer-events: none;
        transform: rotate(180deg);
        transform-origin: 50% 50%;
        transition: transform 0.25s;

        &:focus {
            outline: 0;
        }
    }

    &--open > .ds-expander__header > .ds-expander__header-action {
        transform: rotate(0deg);
    }

    &__content {
        background-color: $opds-color-palette-overlay-darken-100;
        border-bottom: $opds-border-width-default solid
            $opds-color-border-default;
        border-left: $opds-border-width-default solid
            $opds-color-border-element-secondary;
        border-right: $opds-border-width-default solid
            $opds-color-border-element-secondary;
        border-top: $opds-border-width-default solid $opds-color-border-default;
        color: $opds-color-text-default;
        font-size: $opds-font-size-default;
        font-weight: $opds-font-weight-default;
        letter-spacing: $opds-letter-spacing-default;
        line-height: $opds-line-height-default;
        transition: height 0.2s ease;

        &--embedded {
            background: transparent;
            border: 0;
            border-top: $opds-border-width-default solid transparent;
        }

        &--embedded > div {
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;
        }

        > div {
            padding: $opds-space-default bordered($opds-space-small)
                bordered($opds-space-default);
        }

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            > div {
                padding: $opds-space-default bordered($opds-space-default)
                    bordered($opds-space-default);
            }

            &--embedded > div {
                padding-left: 0;
                padding-right: 0;
                padding-top: 0;
            }
        }

        &--indented {
            > div {
                padding: $opds-space-default bordered($opds-space-small)
                    bordered($opds-space-default);

                @media only screen and (min-width: $opds-breakpoint-sm-min) {
                    padding: $opds-space-default bordered($opds-space-default)
                        bordered($opds-space-default);
                }
            }
        }

        &--indented.ds-expander__content--embedded {
            > div {
                padding-top: 0;
            }
        }

        &--unwrapped > div {
            padding: 0;
        }
    }
}
