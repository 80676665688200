@import '@op/opds-tokens/lib/web/op/tokens';

@mixin group {
    margin-bottom: -$opds-space-xxxs;

    > * {
        margin-bottom: $opds-space-xxxs;
    }

    &--columned,
    &--wrapped {
        display: flex;
        flex-wrap: wrap;

        label {
            margin-right: $opds-space-default;
        }
    }

    &--large#{&}--columned,
    &--large#{&}--wrapped,
    &--hero#{&}--columned,
    &--hero#{&}--wrapped {
        label {
            margin-right: $opds-space-large;
        }
    }

    &--columned > * {
        flex: 0 0 auto;
    }

    &--buttonized {
        margin-bottom: -$opds-space-xxs;

        > * {
            margin-bottom: $opds-space-xxs;
        }
    }

    &--buttonized#{&}--large,
    &--buttonized#{&}--hero {
        margin-bottom: -$opds-space-xs;

        > * {
            margin-bottom: $opds-space-xs;
        }
    }

    &--buttonized#{&}--columned {
        margin-right: -$opds-space-xxs;

        label {
            margin-right: $opds-space-xxs;
        }
    }

    &--buttonized#{&}--columned#{&}--large,
    &--buttonized#{&}--columned#{&}--hero {
        margin-right: -$opds-space-xs;

        label {
            margin-right: $opds-space-xs;
        }
    }

    @media only screen and (min-width: $opds-breakpoint-sm-min) {
        &--hero,
        &--hero > * {
            margin-bottom: 0;
        }
    }
}
