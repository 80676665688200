@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

// Size - Vertical - Horizontal - Th Vertical - Icon spacing
$sizes: (
    (
        tiny,
        $opds-space-xxxs,
        $opds-space-xs,
        $opds-space-xxs,
        $opds-size-icon-small
    ),
    (
        small,
        $opds-space-xxs,
        $opds-space-xs,
        $opds-space-xxs,
        $opds-size-icon-small
    ),
    (
        large,
        $opds-space-small,
        $opds-space-default,
        $opds-space-small,
        $opds-size-icon-default
    ),
    (
        hero,
        $opds-space-default,
        $opds-space-default,
        $opds-space-small,
        $opds-size-icon-default
    )
);

@mixin table-spacing(
    $name,
    $vertical,
    $horizontal,
    $th-vertical,
    $icon-spacing
) {
    &--#{$name} {
        th {
            padding: $th-vertical $horizontal;

            &:first-child {
                padding-left: bordered($horizontal);
            }

            &:last-child {
                padding-right: bordered($horizontal);
            }
        }

        td {
            @if $name == 'hero' {
                padding: $opds-space-small $horizontal;
                @media only screen and (min-width: $opds-breakpoint-sm-min) {
                    padding: $vertical $horizontal;
                }
            } @else {
                padding: $vertical $horizontal;
            }

            &:first-child {
                padding-left: bordered($horizontal);
            }

            &:last-child {
                padding-right: bordered($horizontal);
            }
        }

        >,
        thead,
        tbody:first-child {
            tr:first-child {
                td {
                    @if $name == 'hero' {
                        padding-top: bordered($opds-space-small);
                        @media only screen and (min-width: $opds-breakpoint-sm-min) {
                            padding-top: bordered($vertical);
                        }
                    } @else {
                        padding-top: bordered($vertical);
                    }
                }
                th {
                    padding-top: bordered($th-vertical);
                }
            }
        }

        tr:last-child {
            td {
                @if $name == 'hero' {
                    padding-bottom: bordered($opds-space-small);
                    @media only screen and (min-width: $opds-breakpoint-sm-min) {
                        padding-bottom: bordered($vertical);
                    }
                } @else {
                    padding-bottom: bordered($vertical);
                }
            }
            th {
                padding-bottom: bordered($th-vertical);
            }
        }

        &.ds-table--action-rows {
            td:last-child,
            th:last-child {
                padding-right: $opds-space-small + $icon-spacing +
                    $opds-space-xs;
            }
        }

        &.ds-table--zebra tbody tr {
            td {
                @if $name == 'hero' {
                    padding-bottom: bordered($opds-space-small);
                    @media only screen and (min-width: $opds-breakpoint-sm-min) {
                        padding-bottom: bordered($vertical);
                    }
                } @else {
                    padding-bottom: bordered($vertical);
                }
            }
            th {
                padding-bottom: bordered($th-vertical);
            }
        }

        tbody th:only-child {
            padding-bottom: bordered($vertical);
            padding-top: bordered($vertical);
        }

        @if $name == 'hero' or $name == 'large' {
            &.ds-table--tile.ds-table--action-rows td:last-child {
                @media only screen and (min-width: $opds-breakpoint-sm-min) {
                    padding-right: $opds-space-default + $opds-size-icon-default +
                        $opds-space-xs;
                }
            }
        }
    }
}

.ds-table {
    background-color: $opds-color-background-table-default;
    border: $opds-border-width-default solid $opds-color-border-table-default;
    border-collapse: separate;
    color: $opds-color-text-default;
    font-size: $opds-font-size-small;
    font-weight: $opds-font-weight-default;
    line-height: $opds-line-height-small;
    width: 100%;

    caption {
        color: $opds-color-text-default;
        font-size: $opds-font-size-micro;
        font-weight: $opds-font-weight-medium;
        letter-spacing: $opds-letter-spacing-caps;
        line-height: $opds-line-height-micro;
        margin-bottom: $opds-space-xs;
        padding-top: $opds-space-xxxs;
        text-align: left;
        text-transform: uppercase;
    }

    td,
    th {
        padding: $opds-space-xs $opds-space-small;
        vertical-align: top;

        &:first-child {
            padding-left: bordered($opds-space-small);
        }

        &:last-child {
            padding-right: bordered($opds-space-small);
        }
    }

    th:not([align]) {
        text-align: left;
    }

    >,
    thead,
    tbody:first-child {
        tr:first-child {
            td,
            th {
                padding-top: bordered($opds-space-xs);
            }
        }
    }

    tr:last-child {
        td,
        th {
            padding-bottom: bordered($opds-space-xs);
        }
    }

    %header-cell {
        background-color: $opds-color-background-table-header-default;
        color: $opds-color-text-label-default;
        font-size: $opds-font-size-micro;
        font-weight: $opds-font-weight-medium;
        letter-spacing: $opds-letter-spacing-caps;
        line-height: $opds-line-height-micro;
        text-transform: uppercase;
    }

    thead {
        td,
        th {
            @extend %header-cell;
        }

        tr:last-child {
            td,
            th {
                border-bottom: $opds-border-width-default solid
                    $opds-color-border-default;
            }
        }
    }

    tbody th:only-child {
        @extend %header-cell;
        border-bottom: $opds-border-width-default solid
            $opds-color-border-table-default;
        border-top: $opds-border-width-default solid
            $opds-color-border-table-default;
        padding-bottom: bordered($opds-space-xs);
        padding-top: bordered($opds-space-xs);
    }

    tbody:not(:last-child) tr:last-child {
        td,
        th {
            border-bottom: $opds-border-width-default solid
                $opds-color-border-table-default;
        }
    }

    tfoot {
        background-color: $opds-color-background-table-default;
        font-weight: $opds-font-weight-medium;
    }

    &--action-rows {
        th:last-child,
        td:last-child {
            padding-right: $opds-space-small + $opds-size-icon-small +
                $opds-space-xs;
        }
    }

    &--zebra tbody tr {
        &:nth-child(2n) {
            background-color: $opds-color-background-table-row-zebra-default;
        }

        td,
        th {
            border-bottom: $opds-border-width-default solid
                $opds-color-border-table-row-zebra-default;
            padding-bottom: bordered($opds-space-xs);
        }
    }

    &--zebra tbody:last-child tr:last-child {
        td,
        th {
            border-bottom: 0;
        }
    }

    &--action-rows#{&}--zebra tbody tr:nth-child(2n) {
        &:hover,
        &:focus {
            background-color: darken-relative(
                $opds-color-background-table-row-zebra-default,
                $opds-misc-alpha-hover-default
            );
        }

        &:active {
            background-color: darken-relative(
                $opds-color-background-table-default,
                $opds-misc-alpha-active-default
            );
        }
    }

    @each $name, $vertical, $horizontal, $th-vertical, $icon-spacing in $sizes {
        @include table-spacing(
            $name,
            $vertical,
            $horizontal,
            $th-vertical,
            $icon-spacing
        );
    }
}

.ds-table--tile {
    border-left: 0;
    border-right: 0;

    &:last-child {
        border-bottom: 0;
    }

    td,
    th {
        &:first-child {
            padding-left: $opds-space-small;
        }

        &:last-child {
            padding-right: $opds-space-small;
        }
    }

    caption,
    td:nth-child(n),    // Overrides previous :first-child / :last-child
    th:nth-child(n) {
        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            padding-left: $opds-space-default;
            padding-right: $opds-space-default;
        }
    }
}

.ds-table--tile.ds-table--action-rows {
    th:last-child,
    td:last-child {
        padding-right: $opds-space-small + $opds-size-icon-small +
            $opds-space-xs;
        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            padding-right: $opds-space-default + $opds-size-icon-small +
                $opds-space-xs;
        }
    }
}
