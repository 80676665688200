@import '@op/opds-tokens/lib/web/op/tokens';

.ds-stepper {
    display: flex;
    flex-direction: column;

    &__input {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin: 0;
        width: 100%;
    }

    &__notification {
        margin: $opds-space-xxs 0;
    }

    &__decrease-button {
        flex-shrink: 0;
        margin-right: $opds-space-xxs;

        &--small {
            margin-right: $opds-space-xxxs;
        }

        &--large {
            margin-right: $opds-space-xs;
        }

        &--hero {
            margin-right: $opds-space-small;
        }
    }

    &__increase-button {
        flex-shrink: 0;
        margin-left: $opds-space-xxs;

        &--small {
            margin-left: $opds-space-xxxs;
        }

        &--large {
            margin-left: $opds-space-xs;
        }

        &--hero {
            margin-left: $opds-space-small;
        }
    }
}
