@import '@op/opds-tokens/lib/web/op/tokens';

$opds-breakpoints: (
    xs $opds-breakpoint-xs-min $opds-breakpoint-xs-max,
    sm $opds-breakpoint-sm-min $opds-breakpoint-sm-max,
    md $opds-breakpoint-md-min $opds-breakpoint-md-max,
    lg $opds-breakpoint-lg-min $opds-breakpoint-lg-max,
    xl $opds-breakpoint-xl-min $opds-breakpoint-xl-max
) !default;

// These are subset of breakpoints used in op.fi that have
// the closest match with size grades used in grid components.
$opfi-breakpoints: (
    xs 320px 611px,
    sm 612px 767px,
    md 768px 1199px,
    lg 1200px 1899px,
    xl 1900px null
) !default;

@mixin breakpoint($min: null, $max: null) {
    @if ($min and $max) {
        @media only screen and (min-width: #{$min}) and (max-width: #{$max}) {
            @content;
        }
    } @else if ($min) {
        @media only screen and (min-width: #{$min}) {
            @content;
        }
    } @else if ($max) {
        @media only screen and (max-width: #{$max}) {
            @content;
        }
    } @else {
        @content;
    }
}
