@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

@mixin bottom-indicator($value: $opds-color-indicator-default) {
    position: relative;
    z-index: 2;

    &::after {
        bottom: $opds-border-width-default * -1; // Pull the indicator on top of button borders.
        box-shadow: inset 0 gu(-1) 0 $value;
        content: '';
        left: $opds-border-width-default * -1;
        position: absolute;
        right: $opds-border-width-default * -1;
        top: 0;
    }

    &:first-of-type::after {
        border-bottom-left-radius: $opds-border-radius-default;
    }

    &:last-of-type::after {
        border-bottom-right-radius: $opds-border-radius-default;
    }
}

@mixin darken($value) {
    background-color: darken-relative(
        $opds-color-background-input-default,
        $value
    );
}

.ds-segmented-button {
    display: flex;

    label {
        background-color: $opds-color-background-input-default;
        border: $opds-border-width-default solid
            $opds-color-border-input-default;
        color: $opds-color-text-large;
        cursor: pointer;
        font-size: $opds-font-size-medium;
        font-weight: $opds-font-weight-default;
        line-height: $opds-line-height-default;
        min-width: gu(24);
        padding: bordered($opds-space-inset-button-default);
        text-align: center;
        z-index: 1; // For displaying outline correctly

        &:first-of-type,
        &:last-of-type {
            border-radius: $opds-border-radius-default;
        }

        &:first-of-type {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }

        &:last-of-type {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }

        &:not(:first-of-type) {
            margin-left: -$opds-border-width-default; // For displaying outline correctly
        }

        &:hover {
            @include bottom-indicator;
            @include darken($opds-misc-alpha-hover-default);
        }
    }

    input {
        height: 0;
        opacity: 0;
        position: absolute;
        width: 0;

        &:focus + label {
            @include darken($opds-misc-alpha-hover-default);
            outline: $opds-width-outline-focus $opds-style-outline-focus
                $opds-color-outline-focus;
            z-index: 2; // For displaying outline correctly
        }

        &:checked + label {
            @include bottom-indicator;
            color: $opds-color-palette-black;
        }

        &:active + label,
        &:checked + label:active {
            @include darken($opds-misc-alpha-active-default);
        }
    }

    [disabled],
    [disabled]:checked {
        + label {
            background-color: $opds-color-background-disabled;
            border-color: $opds-color-border-disabled;
            color: $opds-color-text-disabled;
            cursor: default;
            pointer-events: none;
            text-decoration: $opds-text-decoration-disabled;
        }

        &:checked + label {
            @include bottom-indicator($opds-color-indicator-passive);
        }
    }

    [aria-invalid='true'] {
        + label {
            background-color: $opds-color-background-input-invalid;
            border-color: $opds-color-border-input-invalid;
            box-shadow: 0 0 gu(1)
                rgba(
                    $opds-color-indicator-error,
                    $opds-misc-alpha-darken-600 / 100%
                );
        }

        &:checked + label,
        + label {
            &:hover {
                @include darken($opds-misc-alpha-hover-default);
                box-shadow: 0 0 gu(2)
                    rgba(
                        $opds-color-indicator-error,
                        $opds-misc-alpha-darken-400 / 100%
                    );
            }

            &:active {
                @include darken($opds-misc-alpha-active-default);
            }
        }
    }

    &--small {
        label {
            font-size: $opds-font-size-default;
            padding: bordered($opds-space-xxxs) bordered($opds-space-default);
        }
    }

    &--large {
        label {
            padding: bordered($opds-space-inset-button-large);
        }
    }

    &--hero {
        label {
            font-size: $opds-font-size-large;
            line-height: $opds-line-height-large;
            padding: bordered($opds-space-inset-button-hero);
        }
    }
}
