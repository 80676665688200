@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

.ds-progress-bar {
    background-color: $opds-color-indicator-default;
    height: gu(2);
    margin: $opds-space-xxs 0;
    overflow: hidden;

    transition: padding-left 0.2s ease-out;

    &::after {
        background-color: $opds-color-background-progressbar-track;
        border: $opds-border-width-default solid
            $opds-color-border-progressbar-track-default;
        border-bottom-color: $opds-color-border-progressbar-track-bottom;
        border-left-color: $opds-color-palette-white;
        content: '';
        display: block;
        height: 100%;
        width: 100%;
    }

    &--empty::after {
        border-left-color: $opds-color-border-progressbar-track-default;
    }
}
