@import '@op/opds-tokens/lib/web/op/tokens';

.ds-icon {
    box-sizing: content-box;
    flex-shrink: 0;
    height: $opds-size-icon-default;
    width: $opds-size-icon-default;

    path {
        fill: $opds-color-icon-default;
    }

    &--small {
        height: $opds-size-icon-small;
        width: $opds-size-icon-small;
    }

    &--large {
        height: $opds-size-icon-large;
        width: $opds-size-icon-large;
    }

    &--action {
        path {
            fill: $opds-color-icon-action;
        }
    }

    &--neutral {
        path {
            fill: $opds-color-icon-neutral;
        }
    }

    &--success {
        path {
            fill: $opds-color-icon-success;
        }
    }

    &--error {
        path {
            fill: $opds-color-icon-error;
        }
    }

    &--disabled {
        path {
            fill: $opds-color-icon-disabled;
        }
    }

    &--inverse {
        path {
            fill: $opds-color-icon-inverse-default;
        }
    }

    &--inverse#{&}--action {
        path {
            fill: $opds-color-icon-inverse-action;
        }
    }

    &--inverse#{&}--neutral {
        path {
            fill: $opds-color-icon-inverse-neutral;
        }
    }

    &--inverse#{&}--success {
        path {
            fill: $opds-color-icon-inverse-success;
        }
    }

    &--inverse#{&}--error {
        path {
            fill: $opds-color-icon-inverse-error;
        }
    }

    &--inverse#{&}--disabled {
        path {
            fill: $opds-color-icon-inverse-disabled;
        }
    }
}
