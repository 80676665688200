@import '@op/opds-tokens/lib/web/op/tokens';

.ds-list {
    border-top: $opds-border-width-default solid $opds-color-border-default;
    display: flex;
    flex-direction: column;
    overflow-wrap: anywhere;
    position: relative;
    top: -$opds-border-width-default;
    word-break: break-word; // depracated, but has better browser support than overflow-wrap

    // overrides <dl> and <ul> element styles
    &#{&} {
        flex-wrap: nowrap;
        margin: $opds-space-default 0 $opds-space-large;
        padding: 0;
    }

    &#{&}--tiny {
        margin-bottom: $opds-space-default;
    }

    &.ds-list--border-inside,
    &.ds-list--border-none {
        border: 0;
        top: 0;
    }

    &.ds-list--border-button {
        border: $opds-border-width-default solid $opds-color-border-default;
        border-radius: $opds-border-radius-default;
    }
}
