@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

.ds-tab {
    a {
        align-items: center;
        background-image: linear-gradient(
            to bottom,
            $opds-color-indicator-default 0,
            $opds-color-indicator-default gu(1)
        );
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 0;
        color: $opds-color-text-tab-default;
        display: flex;
        font-size: $opds-font-size-small;
        font-weight: $opds-font-weight-default;
        letter-spacing: $opds-letter-spacing-caps;
        line-height: $opds-line-height-micro;
        padding: $opds-space-small $opds-space-default;
        text-transform: uppercase;
        white-space: nowrap;

        &:hover,
        &:focus {
            background-color: darken-relative(
                $opds-color-background-default,
                $opds-misc-alpha-hover-default
            );
        }

        &:focus {
            outline: $opds-width-outline-focus $opds-style-outline-focus
                $opds-color-outline-focus;
            outline-offset: -$opds-size-hairline;
        }

        &:active {
            background-color: darken-relative(
                $opds-color-background-default,
                $opds-misc-alpha-active-default
            );
        }
    }

    a:hover,
    a:focus,
    &--active a {
        background-size: 100% gu(1);
    }

    &--active a {
        color: $opds-color-text-tab-selected;

        &:active {
            background-color: $opds-color-palette-overlay-darken-200;
        }
    }

    &#{&} {
        // Override li element style
        margin: 0;

        &::before {
            content: none;
        }
    }
}

.ds-tab--small {
    background-image: linear-gradient(
        to bottom,
        $opds-color-border-default 0,
        $opds-color-border-default gu(5)
    );
    background-position: center right;
    background-repeat: no-repeat;
    background-size: $opds-size-hairline gu(5);

    &:last-child {
        background-image: none;
    }

    a {
        letter-spacing: $opds-letter-spacing-default;
        line-height: $opds-line-height-small;
        padding: $opds-space-xxs $opds-space-small;
        text-transform: none;
    }

    a:hover,
    a:focus,
    &.ds-tab--active a {
        // Make the bottom indicator the width of the text by subtracting
        // side paddings from the full width
        background-size: calc(100% - 2 * #{$opds-space-small}) gu(1);
    }
}
