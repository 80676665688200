@import '@op/opds-tokens/lib/web/op/tokens';

.ds-list-item {
    // overrides <h5> element style
    & &__title {
        color: $opds-color-text-default;
        display: flex;
        flex: 1 0 100%;
        font-size: $opds-font-size-default;
        font-weight: $opds-font-weight-default;
        letter-spacing: $opds-letter-spacing-default;
        line-height: $opds-line-height-default;
        margin-bottom: $opds-space-xxxs;
        padding-top: 0;
        text-transform: none;

        @media only screen and (min-width: $opds-breakpoint-xl-min) {
            color: $opds-color-text-large;
            font-size: $opds-font-size-medium;
        }
    }

    &__title &__action {
        @media only screen and (min-width: $opds-breakpoint-xl-min) {
            font-size: inherit;
            line-height: inherit;
        }
    }

    &--icon-default &__title &__icon {
        margin-bottom: -$opds-size-icon-default;
    }

    &--icon-large &__title &__icon {
        margin-bottom: -$opds-size-icon-large;
    }

    & &__title + &__content {
        color: $opds-color-text-secondary;
        font-size: $opds-font-size-small;
        line-height: $opds-line-height-small;
    }

    .ds-list--tiny &__title {
        font-size: $opds-font-size-small;
        line-height: $opds-line-height-small;
    }

    .ds-list--tiny &__title + &__content {
        font-size: $opds-font-size-micro;
        font-weight: $opds-font-weight-medium;
        line-height: $opds-line-height-micro;
    }

    .ds-list--small &__title {
        color: $opds-color-text-default;
        font-size: $opds-font-size-default;
    }

    .ds-list--large &__title {
        color: $opds-color-text-large;
        font-size: $opds-font-size-medium;

        @media only screen and (min-width: $opds-breakpoint-xl-min) {
            font-size: $opds-font-size-large;
            line-height: $opds-line-height-large;
        }
    }

    .ds-list--large &__title + &__content {
        color: $opds-color-text-secondary;
        font-size: $opds-font-size-default;
        line-height: $opds-line-height-default;
    }
}
