@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

// These and other similar line-height/padding changes are because special
// characters were being cut off https://oppjira.eficode.com/browse/OPDS-2338
$line-height-default: 3.2rem;
$line-height-hero: 3.6rem;

.ds-text-input {
    margin: 0;
    width: 100%;

    &--unit-inside input {
        background-color: inherit;
        border: 0;
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        width: 100%;

        &:focus {
            outline: 0;
        }
    }

    &--amount,
    &--unit-inside input,
    &--unit-outside input {
        min-width: 0;
        text-align: right;
    }

    &--unit-inside input {
        padding: 0;
    }

    &--unit-inside,
    &--unit-outside {
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
    }

    &--unit-inside &__unit {
        color: $opds-color-text-secondary;
        margin-left: $opds-space-xs;
    }

    &--unit-outside &__unit {
        color: $opds-color-text-label-default;
        font-size: $opds-font-size-small;
        font-weight: $opds-font-weight-default;
        line-height: $opds-line-height-small;
        margin-left: $opds-space-xs;
    }

    // hide spin button on input type number
    &[type='number'],
    input[type='number'] {
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }

    &::placeholder,
    input::placeholder {
        color: $opds-color-text-placeholder;
        line-height: $line-height-default;
    }
}

textarea.ds-input-behavior {
    height: auto;
    line-height: $opds-line-height-default;
    overflow: auto; // Hide scrollbar on IE when it is not needed
    padding: bordered($opds-space-inset-input-default);
    resize: none;

    &--small {
        padding: bordered($opds-space-inset-input-small);
    }

    &--large,
    &--hero {
        padding: bordered($opds-space-inset-input-large);
    }
}

.ds-input-behavior {
    background-color: $opds-color-background-input-default;
    border: $opds-border-width-default solid $opds-color-border-input-default;
    border-radius: 0;
    color: $opds-color-text-default;
    font-family: inherit;
    font-size: $opds-font-size-medium;
    font-weight: $opds-font-weight-default;
    line-height: $line-height-default;
    padding: bordered($opds-space-xxxs) bordered($opds-space-xs);
    width: 100%;

    &:hover {
        border-color: $opds-color-border-input-hover;
    }

    &--focus {
        border-color: $opds-color-border-input-hover;
        outline-color: $opds-color-outline-input-focus;
        outline-offset: 0;
        outline-style: $opds-style-outline-input-focus;
        outline-width: $opds-width-outline-focus;
    }

    &--disabled {
        background-color: $opds-color-background-disabled;
        border-color: $opds-color-border-disabled;
        color: $opds-color-text-disabled;
        // disable pointer events altogether on disabled inputs
        pointer-events: none;
        text-decoration: $opds-text-decoration-disabled;

        &::placeholder {
            color: $opds-color-text-placeholder;
            text-decoration: $opds-text-decoration-disabled;
        }

        // Set unit color to match disabled color
        + .ds-text-input__unit,
        & .ds-text-input__unit {
            color: $opds-color-text-disabled;
            text-decoration: $opds-text-decoration-disabled;
        }
    }

    &--invalid {
        background-color: $opds-color-background-input-invalid;
        border-color: $opds-color-border-input-invalid;
        box-shadow: 0 0 gu(1)
            rgba(
                $opds-color-indicator-error,
                $opds-misc-alpha-darken-600 / 100%
            );

        &:hover,
        &.ds-input-behavior--focus {
            background-color: $opds-color-background-input-default;
            border-color: $opds-color-border-input-hover;
            box-shadow: 0 0 gu(2)
                rgba(
                    $opds-color-indicator-error,
                    $opds-misc-alpha-darken-400 / 100%
                );
        }
    }

    &#{&}--readonly {
        border-color: transparent;
        border-left: 0;
        border-right: 0;
        outline: none;
        padding-left: 0;
        padding-right: 0;

        &:hover {
            border-color: transparent;
        }
    }

    &--small {
        font-size: $opds-font-size-default;
        line-height: $opds-line-height-default;
        padding: bordered($opds-space-inset-input-small);
    }

    &--large,
    &--hero {
        padding: bordered($opds-space-xxs) bordered($opds-space-small);
    }
}

@media only screen and (min-width: $opds-breakpoint-sm-min) {
    .ds-input-behavior--hero + .ds-text-input__unit {
        font-size: $opds-font-size-default;
        line-height: $opds-line-height-large;
    }

    .ds-input-behavior--hero {
        font-size: $opds-font-size-large;
        line-height: $line-height-hero;
        padding: bordered($opds-space-xs) bordered($opds-space-default);
    }

    textarea.ds-input-behavior--hero {
        line-height: $opds-line-height-large;
        padding: bordered($opds-space-inset-input-hero);
    }
}
