@import '@op/opds-tokens/lib/web/op/tokens';
@import '../styles/svg-to-url';

@function logo-mark() {
    @return svg-to-url(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path fill="#ff6a10" d="M25 0a25 25 0 100 50 25 25 0 100-50z"/><path d="M27.5 44.5h-5V34.2c-1.7 1.1-3.8 1.8-6 1.8-6.1 0-11-4.9-11-11s4.9-11 11-11c2.2 0 4.3.7 6 1.8V5.5h5v10.3c1.7-1.1 3.8-1.8 6-1.8 6.1 0 11 4.9 11 11s-4.9 11-11 11c-2.2 0-4.3-.7-6-1.8v10.3zm6-25.5c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm-17 0c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6z" fill="#fff"/></svg>'
    );
}

.ds-service-identity {
    display: inline-flex;
    vertical-align: top;
    white-space: nowrap;

    &__logo-mark {
        background-image: logo-mark();
        background-repeat: no-repeat;
    }

    &__name {
        border-left: $opds-border-width-default solid
            $opds-color-palette-grey-500;
        letter-spacing: $opds-letter-spacing-semitight;
    }
}
