@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

.ds-loading {
    animation: ds-loading-rotate 1s infinite linear;
    border-radius: 50%;
    display: inline-block;
    height: gu(10);
    position: relative;
    vertical-align: middle;
    width: gu(10);

    &__spinner {
        fill: transparent;
        stroke-width: 10%;
    }

    &__gradient {
        stop:last-child {
            stop-opacity: 0;
        }
    }
}

@keyframes ds-loading-rotate {
    0% {
        transform: rotateZ(0deg);
    }

    100% {
        transform: rotateZ(360deg);
    }
}
