@import '@op/opds-tokens/lib/web/op/functions';
@import '@op/opds-tokens/lib/web/op/tokens';

.ds-list-item {
    @mixin item-indent($indent) {
        padding-left: $indent;
        padding-right: $indent;

        &--interactive {
            padding-right: $opds-space-xs + $opds-size-icon-default + $indent;
        }

        &--interactive .ds-list-item__link__icon {
            padding-right: $indent;
        }
    }

    @mixin item-padding($padding) {
        padding-bottom: max(0, bordered($padding));
        padding-top: $padding;
    }

    @include item-padding($opds-space-xs);
    align-items: center;
    border-bottom: $opds-border-width-default solid $opds-color-border-default;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;

    // overrides <li> element style
    &#{&} {
        list-style: none;
        margin-bottom: 0;

        &::before {
            content: none;
        }
    }

    .ds-list--border-none.ds-list--border-none & {
        @include item-padding(0);
        border: 0;
        margin-bottom: $opds-space-small;
    }

    .ds-list--tiny &,
    .ds-list--small & {
        @include item-padding($opds-space-xxs);
    }

    .ds-list--tiny.ds-list--border-none &,
    .ds-list--small.ds-list--border-none & {
        margin-bottom: $opds-space-xs;
    }

    .ds-list--large & {
        @include item-padding($opds-space-small);
    }

    .ds-list--large.ds-list--border-none & {
        margin-bottom: $opds-space-default;
    }

    .ds-list:not(.ds-list--border-none) &--interactive {
        cursor: pointer;
        padding-right: $opds-space-xs + $opds-size-icon-default +
            $opds-space-small;

        &:hover,
        &:focus,
        &:focus-within {
            background-color: rgba(
                $opds-color-palette-black,
                $opds-misc-alpha-hover-default / 100%
            );
        }

        &:active {
            background-color: rgba(
                $opds-color-palette-black,
                $opds-misc-alpha-active-default / 100%
            );
        }

        &:focus,
        &:focus-within {
            outline: $opds-color-outline-focus $opds-width-outline-focus
                $opds-style-outline-focus;
            outline-offset: 0;
        }
    }

    &--interactive &__link {
        background: none;
        border: 0;
        color: inherit;
        cursor: pointer;
        display: flex;
        flex-basis: 100%;
        font-size: inherit;
        font-weight: inherit;
        padding: 0;

        &:focus {
            outline: none;
        }

        &::after {
            bottom: 0;
            content: '';
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .ds-list:not(.ds-list--border-none) &--interactive &__link__icon {
        bottom: 0;
        margin: auto 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    .ds-list--border-inside &,
    .ds-list--border-button & {
        // hides visible top/bottom borders without a change
        // in the item padding
        &:last-child {
            border-bottom-color: transparent;
        }
    }

    .ds-list--border-button &,
    .ds-list.ds-list--indented & {
        @include item-indent($opds-space-small);
    }

    &--disabled,
    &--disabled &__link {
        cursor: default;
        pointer-events: none;
    }

    // overrides any other text coloring
    .ds-list.ds-list &#{&}--disabled * {
        color: $opds-color-text-disabled;
        text-decoration: $opds-text-decoration-disabled;
    }

    @media only screen and (min-width: $opds-breakpoint-sm-min) {
        .ds-list.ds-list--indented & {
            @include item-indent($opds-space-default);
        }
    }
}
