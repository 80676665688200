@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

@mixin large-mobile-paddings {
    .ds-modal--large & {
        padding-left: $opds-space-small;
        padding-right: $opds-space-small;

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            padding-left: $opds-space-default;
            padding-right: $opds-space-default;
        }
    }
}

// To avoid stylelint nesting rules
@mixin large-mobile-title-paddings {
    .ds-modal--large & {
        padding-left: $opds-space-small;

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            padding-left: $opds-space-default;
        }
    }
}

.ds-modal__overlay {
    align-items: center;
    background-color: $opds-color-background-overlay-disabled;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: opacity 250ms ease-in-out;
    width: 100%;
    z-index: 1000;

    &--enter {
        opacity: 1;
    }

    &--exit {
        opacity: 0;
    }

    &--large {
        display: block;
    }
}

.ds-modal {
    background: $opds-color-background-default;
    border-radius: $opds-border-radius-default;
    box-shadow: $opds-elevation-modal;
    display: flex;
    flex-direction: column;
    height: auto;
    margin: $opds-space-large;
    max-height: calc(100vh - #{$opds-space-large * 2});
    min-width: gu(74);
    outline: none;
    overflow: hidden;
    width: 100%;

    @media only screen and (min-width: $opds-breakpoint-sm-min) {
        width: 8 / 12 * 100%; // grid columns
    }

    @media only screen and (min-width: $opds-breakpoint-lg-min) {
        max-width: gu(162);
        width: 6 / 12 * 100%; // grid columns
    }

    &--large {
        height: 100%;
        margin: 0;
        max-height: 100%;
        width: 100%;

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            height: auto;
            margin: $opds-space-xxxl auto 0 auto;
            max-height: calc(100vh - #{$opds-space-xxxl * 2});
            max-width: gu(228);
            width: 10 / 12 * 100%; // grid columns
        }

        @media only screen and (min-width: $opds-breakpoint-lg-min) {
            width: 8 / 12 * 100%; // grid columns
        }

        @media only screen and (min-width: $opds-breakpoint-xl-min) {
            margin: $opds-space-xxxxl auto 0 auto;
            max-height: calc(100vh - #{$opds-space-xxxxl * 2});
        }
    }

    &:focus {
        outline: none;
    }

    &--centered {
        display: flex;
    }

    &__header {
        align-items: flex-start;
        display: flex;
        flex-shrink: 0;
        justify-content: space-between;
        width: 100%;
    }

    &__title {
        @include large-mobile-title-paddings;
        color: $opds-color-text-large;
        font-size: $opds-font-size-large;
        font-weight: $opds-font-weight-bold;
        line-height: $opds-line-height-default;
        margin: $opds-space-default 0;
        padding-left: $opds-space-default;

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            font-size: $opds-font-size-6;
            font-weight: $opds-font-weight-medium;
            line-height: $opds-line-height-large;
        }

        &--full-width {
            margin: $opds-space-default 0;
            padding: 0 $opds-space-default;
        }
    }

    &__body {
        @include large-mobile-paddings;
        color: $opds-color-text-default;
        flex: 1 1 auto;
        font-size: $opds-font-size-default;
        font-weight: $opds-font-weight-default;
        line-height: $opds-line-height-default;
        margin: 0 0 $opds-space-default;
        max-height: 100%;
        overflow-y: auto;
        padding: 0 $opds-space-default 0 $opds-space-default;
        position: relative;

        // Prevent focus trap. Issue OPDS-1842
        .ds-option input {
            height: $opds-size-hairline;
        }
    }

    &__footer {
        @include large-mobile-paddings;
        display: flex;
        flex-shrink: 0;
        margin-bottom: $opds-space-default;
        padding: 0 $opds-space-default;
    }

    &--open {
        overflow: hidden;
    }

    &__close-button {
        margin: $opds-space-xs;
    }
}
