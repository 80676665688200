@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

@mixin sizes($small, $default, $large) {
    height: $default;
    width: $default;

    &--small {
        height: $small;
        width: $small;
    }

    &--large {
        height: $large;
        width: $large;
    }
}

.ds-circled-icon {
    @include sizes(gu(12), gu(14), gu(20));

    display: inline-block;

    path {
        fill: $opds-color-icon-large;
    }

    &--inverse {
        path {
            fill: $opds-color-icon-inverse-large;
        }
    }

    @media only screen and (min-width: $opds-breakpoint-md-min) {
        @include sizes(gu(14), gu(18), gu(24));
    }

    @media only screen and (min-width: $opds-breakpoint-lg-min) {
        @include sizes(gu(15), gu(20), gu(28));
    }

    @media only screen and (min-width: $opds-breakpoint-xl-min) {
        @include sizes(gu(16), gu(24), gu(40));
    }
}
