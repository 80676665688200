@import '@op/opds-tokens/lib/web/op/functions';
@import '@op/opds-tokens/lib/web/op/tokens';

.ds-anim-check {
    @mixin size($dimension) {
        height: $dimension;
        width: $dimension;
    }

    $anim-initial-delay: 0.1s;
    $anim-circle-duration: 0.5s;
    $anim-check-duration: 0.4s;

    @include size(gu(18));
    display: inline-block;
    font-size: 0; // fixes height when small
    vertical-align: middle;

    circle,
    path {
        animation: ease-out forwards drawAnim;
        fill: none;
        stroke: $opds-color-indicator-success;
    }

    circle {
        animation-delay: $anim-initial-delay;
        animation-duration: $anim-circle-duration;
        stroke-dasharray: 300, 300; // approximate of the circle radius length
        stroke-dashoffset: -300; // draw counterclockwise
        stroke-width: 4; // 1 grid unit in svg pixels
        transform: rotate(
            213deg
        ); // extension of the left hand of the checkmark
        transform-origin: 0 0;
    }

    path {
        animation-delay: $anim-initial-delay + $anim-circle-duration;
        animation-duration: $anim-check-duration;
        stroke-dasharray: 100, 100; // approximate of checkmark path length
        stroke-dashoffset: 100; // draw from left to right
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 4.2; // from checkmark icon
    }

    @keyframes drawAnim {
        to {
            stroke-dashoffset: 0;
        }
    }

    @media only screen and (min-width: $opds-breakpoint-sm-min) {
        @include size(gu(24));
    }
}
