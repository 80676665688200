@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

.ds-tabs {
    &__nav {
        align-items: center;
        background-image: linear-gradient(
            to bottom,
            $opds-color-border-default 0,
            $opds-color-border-default $opds-border-width-default
        );
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 100% $opds-border-width-default;
        display: flex;
        margin: $opds-space-small (-$opds-space-small) $opds-space-default;
        position: relative;

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            margin: $opds-space-default (-$opds-space-default) $opds-space-large;
        }
    }

    &__nav__list {
        align-items: center;
        display: flex;
        -ms-overflow-style: none;
        overflow-x: scroll;
        position: relative;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__nav &__nav__list {
        // Override ul element style
        margin: 0;
        padding: 0;
    }

    &__button {
        display: inline-flex;
        overflow: visible;
        transition: width 0.2s;
        width: initial;

        &--first {
            margin-right: $opds-space-xs;
        }

        &--last {
            margin-left: $opds-space-xs;
        }

        &--hidden {
            border: 0;
            margin: 0;
            padding: 0;
            visibility: hidden;
            width: 0;
        }
    }

    &__panel:focus {
        outline: $opds-width-outline-focus $opds-style-outline-focus
            $opds-color-outline-focus;
    }

    &--small &__nav {
        background-image: none;
        margin: 0 -#{$opds-space-small};
    }

    &--small &__nav__list {
        flex-wrap: wrap;

        li {
            margin-bottom: $opds-space-small;
        }
    }
}
