@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';
@import '@op/opds-tokens/lib/web/op/icon/navigation';

.ds-table-action-row {
    cursor: pointer;

    &:focus {
        outline: $opds-color-outline-focus $opds-style-outline-focus
            $opds-width-outline-focus;
    }

    &:hover,
    &:focus {
        background-color: darken-relative(
            $opds-color-background-table-default,
            $opds-misc-alpha-hover-default
        );
    }

    &:active {
        background-color: darken-relative(
            $opds-color-background-table-default,
            $opds-misc-alpha-active-default
        );
    }

    td:last-child {
        background-image: opds-icon-navigation-linkinternal(
            $opds-color-indicator-default
        );
        background-position: right $opds-space-small center;
        background-repeat: no-repeat;
        background-size: $opds-size-icon-small $opds-size-icon-small;
        padding-right: $opds-space-small + $opds-size-icon-small +
            $opds-space-xs;

        .ds-table--large &,
        .ds-table--hero & {
            background-size: $opds-size-icon-default $opds-size-icon-default;
        }
    }
}

.ds-table--tile .ds-table-action-row td:last-child {
    @media only screen and (min-width: $opds-breakpoint-sm-min) {
        background-position: right $opds-space-default center;
        padding-right: $opds-space-default + $opds-size-icon-small +
            $opds-space-xs;
    }
}

.ds-table--hero.ds-table--tile .ds-table-action-row td:last-child,
.ds-table--large.ds-table--tile .ds-table-action-row td:last-child {
    @media only screen and (min-width: $opds-breakpoint-sm-min) {
        background-position: right $opds-space-default center;
        padding-right: $opds-space-default + $opds-size-icon-default +
            $opds-space-xs;
    }
}
