@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

.ds-badge {
    display: flex;

    span {
        align-items: center;
        background-color: $opds-color-background-tertiary;
        border: $opds-border-width-default solid
            rgba($opds-color-palette-black, $opds-misc-alpha-darken-300 / 100%);
        border-radius: gu(3);
        color: $opds-color-text-large;
        display: flex;
        flex-grow: 0;
        font-size: $opds-font-size-micro;
        font-weight: $opds-font-weight-medium;
        height: gu(6);
        justify-content: center;
        line-height: $opds-line-height-micro;
        white-space: nowrap;
        width: gu(6);
    }

    &--large span {
        padding: 0 $opds-space-xs;
        width: auto;
    }

    &--dot span {
        height: gu(2);
        width: gu(2);
    }

    &--alert {
        align-items: center;
        display: flex;

        span {
            background-color: $opds-color-palette-neutral-700;
            margin-left: $opds-space-xxs;
        }
    }

    &--status-ok span {
        background-color: $opds-color-palette-green-700;
    }

    &--status-warning span {
        background-color: #febe1b; // No token for this color. From design spec.
        border-color: rgba(
            $opds-color-palette-black,
            $opds-misc-alpha-darken-500 / 100%
        );
    }

    &--status-critical,
    &--alert-error,
    &--alert {
        span {
            color: $opds-color-text-inverse-default;
        }
    }

    &--status-critical,
    &--alert-error {
        span {
            background-color: $opds-color-indicator-error;
        }
    }
}
