@import '../checkbox-group/group';
@import '../checkbox/selectable';
@import '../styles/svg-to-url';

@function selected($color) {
    @return svg-to-url(
        '<?xml version="1.0" encoding="UTF-8"?><svg width="32" height="32" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><radialGradient id="dot"><stop stop-color="#{$color}" /><stop offset="47.5%" stop-color="#{$color}" /><stop offset="52.5%" stop-color="#{$opds-color-background-input-default}" stop-opacity="0" /></radialGradient></defs><circle cx="16" cy="16" r="16" fill="url(#dot)" /></svg>'
    );
}

.ds-radio-group {
    @include group;
}

.ds-option {
    @include selectable;

    &__label::before {
        border-radius: 50vh;
    }

    :checked + ::before {
        background-image: selected($opds-color-indicator-default);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-origin: border-box;
    }

    &--disabled :checked + ::before {
        background-image: selected($opds-color-indicator-passive);
    }
}
