@import '@op/opds-tokens/lib/web/op/tokens';

.ds-input-error {
    color: $opds-color-text-default;
    display: flex;
    font-size: $opds-font-size-small;
    font-weight: $opds-font-weight-medium;
    line-height: $opds-line-height-small;
    margin: $opds-space-xxs 0;

    .ds-icon {
        flex-shrink: 0;
        margin-right: $opds-space-xxs;
    }

    @media screen and (min-width: $opds-breakpoint-sm-min) {
        &--hero {
            font-size: $opds-font-size-default;
            line-height: $opds-line-height-default;

            .ds-icon {
                height: $opds-size-icon-default;
                width: $opds-size-icon-default;
            }
        }
    }
}
