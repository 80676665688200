@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

$ns: '.opds';

@mixin ns {
    #{$ns} &,
    &#{$ns} {
        @content;
    }
}

#{$ns} {
    color: $opds-color-text-default;
    font-size: $opds-font-size-default;
    font-weight: $opds-font-weight-default;
    letter-spacing: $opds-letter-spacing-default;
    line-height: $opds-line-height-default;

    @media only screen and (min-width: $opds-breakpoint-xl-min) {
        color: $opds-color-text-large;
        font-size: $opds-font-size-medium;
    }
}

h1 {
    @include ns {
        color: $opds-color-text-large;
        font-family: inherit;
        font-size: $opds-font-size-7;
        font-weight: $opds-font-weight-default;
        letter-spacing: $opds-letter-spacing-default;
        line-height: $opds-line-height-5;
        margin-bottom: $opds-space-default;
        padding-top: $opds-space-small;

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            font-size: $opds-font-size-9;
            line-height: $opds-line-height-7;
        }

        @media only screen and (min-width: $opds-breakpoint-lg-min) {
            font-size: $opds-font-size-10;
            font-weight: $opds-font-weight-thin;
            line-height: $opds-line-height-8;
        }

        @media only screen and (min-width: $opds-breakpoint-xl-min) {
            font-size: $opds-font-size-11;
            line-height: $opds-line-height-9;
        }
    }
}

h2 {
    @include ns {
        color: $opds-color-text-large;
        font-family: inherit;
        font-size: $opds-font-size-6;
        font-weight: $opds-font-weight-default;
        letter-spacing: $opds-letter-spacing-default;
        line-height: $opds-line-height-4;
        margin-bottom: $opds-space-small;
        padding-top: $opds-space-xs;

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            font-size: $opds-font-size-7;
            line-height: $opds-line-height-5;
        }

        @media only screen and (min-width: $opds-breakpoint-lg-min) {
            font-size: $opds-font-size-8;
            line-height: $opds-line-height-6;
        }

        @media only screen and (min-width: $opds-breakpoint-xl-min) {
            font-size: $opds-font-size-9;
            line-height: $opds-line-height-7;
        }
    }
}

h3 {
    @include ns {
        color: $opds-color-text-large;
        font-family: inherit;
        font-size: $opds-font-size-4;
        font-weight: $opds-font-weight-medium;
        letter-spacing: $opds-letter-spacing-default;
        line-height: $opds-line-height-3;
        margin-bottom: $opds-space-small;
        padding-top: $opds-space-xxxs;

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            font-size: $opds-font-size-5;
            line-height: $opds-line-height-3;
        }

        @media only screen and (min-width: $opds-breakpoint-xl-min) {
            font-size: $opds-font-size-6;
            line-height: $opds-line-height-4;
        }
    }
}

h4 {
    @include ns {
        color: $opds-color-text-default;
        font-family: inherit;
        font-size: $opds-font-size-3;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-weight: $opds-font-weight-bold;
        letter-spacing: $opds-letter-spacing-default;
        line-height: $opds-line-height-2;
        margin-bottom: $opds-space-xxs;
        padding-top: $opds-space-xxxs;

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            color: $opds-color-text-large;
            font-size: $opds-font-size-4;
        }

        @media only screen and (min-width: $opds-breakpoint-xl-min) {
            font-size: $opds-font-size-5;
            line-height: $opds-line-height-3;
        }
    }
}

h5 {
    @include ns {
        color: $opds-color-text-default;
        font-family: $opds-font-platform-stack-default;
        font-size: $opds-font-size-2;
        font-weight: $opds-font-weight-medium;
        letter-spacing: $opds-letter-spacing-caps;
        line-height: $opds-line-height-2;
        margin-bottom: $opds-space-xxxs;
        padding-top: $opds-space-xxxs;
        text-transform: uppercase;

        @media only screen and (min-width: $opds-breakpoint-xl-min) {
            font-size: $opds-font-size-3;
            line-height: $opds-line-height-3;
        }
    }
}

h6 {
    @include ns {
        color: $opds-color-text-default;
        font-family: inherit;
        font-size: $opds-font-size-1;
        font-weight: $opds-font-weight-medium;
        letter-spacing: $opds-letter-spacing-caps;
        line-height: $opds-line-height-1;
        margin-bottom: $opds-space-xxxs;
        padding-top: $opds-space-xxxs;
        text-transform: uppercase;

        @media only screen and (min-width: $opds-breakpoint-xl-min) {
            color: $opds-color-text-large;
            font-size: $opds-font-size-2;
            line-height: $opds-line-height-2;
        }
    }
}

aside {
    @include ns {
        color: $opds-color-text-secondary;
    }
}

p {
    @include ns {
        font: inherit; // override legacy opfi-base-theme style
        line-height: $opds-line-height-default;
        margin-bottom: $opds-space-default;
        padding: 0; // override legacy opfi-base-theme style
    }
}

small {
    @include ns {
        font-size: $opds-font-size-small;
        line-height: $opds-line-height-small;
    }
}

b,
strong {
    @include ns {
        color: $opds-color-text-bold;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-weight: $opds-font-weight-bold;
    }
}

em,
i {
    @include ns {
        // Prevent use of italic, because using it is against OP brand guidelines
        font-style: inherit;
    }
}

s {
    @include ns {
        text-decoration: line-through;
    }
}

a {
    @include ns {
        background-image: linear-gradient(
            to right,
            $opds-color-border-input-hover 0%,
            $opds-color-border-input-hover 100%
        );
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: 100% $opds-size-hairline;
        color: $opds-color-text-link;
        font-weight: $opds-font-weight-medium;
        text-decoration: none;

        &:hover {
            background-size: 100% $opds-size-tiny;
        }

        &:focus {
            background-size: 100% $opds-size-tiny;
            outline: $opds-width-outline-focus $opds-style-outline-focus
                $opds-color-outline-focus;
            outline-offset: $opds-size-tiny;
        }
    }
}

ul,
ol {
    @include ns {
        margin: $opds-space-default 0 $opds-space-large;
        padding-left: $opds-space-default;

        li {
            font-size: inherit;
            line-height: inherit;
            margin-bottom: $opds-space-small;

            &::before {
                color: $opds-color-text-label-default;
                display: inline-block;
                margin-left: -$opds-space-default;
                width: $opds-space-default;
            }
        }
    }
}

ul {
    @include ns {
        li {
            list-style: none;

            &::before {
                content: '\2022';
            }
        }
    }
}

ol {
    @include ns {
        counter-reset: li;
        list-style: none;

        li {
            counter-increment: li;

            &::before {
                content: counter(li) '.';
                font-size: $opds-font-size-small;
                font-weight: $opds-font-weight-medium;
                line-height: $opds-line-height-small;
            }
        }
    }
}

dl {
    @include ns {
        margin: $opds-space-default 0 $opds-space-large;
        width: 100%;

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            display: flex;
            flex-wrap: wrap;
        }

        dt {
            box-sizing: border-box;
            color: $opds-color-text-label-default;
            font-size: $opds-font-size-small;
            font-weight: $opds-font-weight-medium;
            line-height: $opds-line-height-small;
            margin-bottom: $opds-space-xxxs;
            padding-top: 0;
            text-align: left;

            @media only screen and (min-width: $opds-breakpoint-sm-min) {
                flex-basis: 33.333%;
                margin-bottom: $opds-space-small;
                padding-right: $opds-space-inline-grid-default;
                padding-top: (
                        $opds-line-height-default - $opds-line-height-small
                    ) / 2; // to align with the dd
                text-align: right;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        dd {
            box-sizing: border-box;
            color: $opds-color-text-large;
            font-size: $opds-font-size-medium;
            font-weight: $opds-font-weight-default;
            line-height: $opds-line-height-default;
            margin-bottom: $opds-space-small;

            &:last-of-type {
                margin-bottom: 0;
            }

            @media only screen and (min-width: $opds-breakpoint-sm-min) {
                flex-basis: 66.666%;
                padding-left: $opds-space-inline-grid-default;
            }
        }
    }
}

blockquote {
    @include ns {
        color: $opds-color-text-large;
        font-size: $opds-font-size-large;
        line-height: $opds-line-height-large;
        margin-bottom: $opds-space-xl;
        margin-top: $opds-space-xl;
        padding-left: $opds-space-xl;
        position: relative;

        &::before {
            color: $opds-color-indicator-default;
            content: '\201D';
            display: block;
            font-size: $opds-font-size-12;
            font-style: normal;
            font-weight: $opds-font-weight-default;
            left: 0;
            line-height: 1em;
            position: absolute;
            top: -$opds-space-xxxs;
        }

        footer {
            color: $opds-color-text-secondary;
            font-size: $opds-font-size-small;
            line-height: $opds-line-height-small;
            margin-top: $opds-space-small;
        }

        @media only screen and (min-width: $opds-breakpoint-xl-min) {
            font-size: $opds-font-size-6;
            line-height: 3.2rem;
            padding-left: $opds-space-xxl;

            &::before {
                font-size: 15.2rem;
            }

            footer {
                font-size: $opds-font-size-default;
                line-height: $opds-line-height-default;
            }
        }
    }
}

q {
    @include ns {
        &::after {
            content: '\201D';
        }

        &::before {
            content: '\201C';
        }
    }
}

code,
pre {
    @include ns {
        font-family: 'Courier New', Courier, monospace;
        font-size: 106%;
        font-style: normal;
    }
}

hr {
    @include ns {
        border-color: $opds-color-border-default;
        border-style: solid;
        border-width: $opds-border-width-default 0 0;
        margin: $opds-space-default 0 bordered($opds-space-default);
    }
}

button,
input,
optgroup,
option,
select,
textarea {
    @include ns {
        font-family: inherit;
    }
}
