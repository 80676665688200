@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';
@import 'breakpoint';

$column-count: 12;
$opfi-selector: '[class^="op-theme-"]';

@mixin columns($breakpoint) {
    @for $i from 0 through $column-count {
        @if ($i == 0) {
            // Auto width column
            .ds-col--#{$breakpoint} {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
            }
        } @else {
            .ds-col--#{$breakpoint}-#{$i} {
                flex-basis: (($i / $column-count) * 100%);
                max-width: (($i / $column-count) * 100%);
            }
        }
    }
}

@mixin offsets($breakpoint) {
    @for $i from 0 to $column-count {
        .ds-col--#{$breakpoint}--offset-#{$i} {
            margin-left: (($i / $column-count) * 100%);
        }
    }
}

@mixin alignment($breakpoint) {
    .ds-row--start-#{$breakpoint} {
        justify-content: flex-start;
    }

    .ds-row--center-#{$breakpoint} {
        justify-content: center;
    }

    .ds-row--end-#{$breakpoint} {
        justify-content: flex-end;
    }

    .ds-row--top-#{$breakpoint} {
        align-items: flex-start;
    }

    .ds-row--middle-#{$breakpoint} {
        align-items: center;
    }

    .ds-row--bottom-#{$breakpoint} {
        align-items: flex-end;
    }
}

@mixin distribution($breakpoint) {
    .ds-row--around-#{$breakpoint} {
        justify-content: space-around;
    }

    .ds-row--between-#{$breakpoint} {
        justify-content: space-between;
    }
}

@mixin reordering($breakpoint) {
    .ds-col--first-#{$breakpoint} {
        order: -1;
    }

    .ds-col--last-#{$breakpoint} {
        order: 1;
    }

    .ds-col--initial-#{$breakpoint} {
        order: initial;
    }
}

.ds-row {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: gu(2);
    margin-right: gu(2);

    @include breakpoint($opds-breakpoint-sm-min) {
        margin-left: gu(3);
        margin-right: gu(3);
    }

    &--reverse {
        flex-direction: row-reverse;
    }
}

.ds-col {
    box-sizing: border-box;
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: gu(2);
    padding-right: gu(2);

    @include breakpoint($opds-breakpoint-sm-min) {
        padding-left: gu(3);
        padding-right: gu(3);
    }
}

@each $breakpoint-name, $min-width, $max-width in $opds-breakpoints {
    @include breakpoint($min-width) {
        body:not(#{$opfi-selector}) {
            @include columns($breakpoint-name);
            @include offsets($breakpoint-name);
            @include alignment($breakpoint-name);
            @include distribution($breakpoint-name);
            @include reordering($breakpoint-name);
        }
    }
}

@each $breakpoint-name, $min-width, $max-width in $opfi-breakpoints {
    @include breakpoint($min-width) {
        body#{$opfi-selector} {
            @include columns($breakpoint-name);
            @include offsets($breakpoint-name);
            @include alignment($breakpoint-name);
            @include distribution($breakpoint-name);
            @include reordering($breakpoint-name);
        }
    }
}

@each $breakpoint-name, $min-width, $max-width in $opds-breakpoints {
    @include breakpoint($min-width, $max-width) {
        body:not(#{$opfi-selector}) .ds-col--hidden-#{$breakpoint-name} {
            display: none;
        }
    }
}

@each $breakpoint-name, $min-width, $max-width in $opfi-breakpoints {
    @include breakpoint($min-width, $max-width) {
        body#{$opfi-selector} .ds-col--hidden-#{$breakpoint-name} {
            display: none;
        }
    }
}
