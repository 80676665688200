@import '@op/opds-tokens/lib/web/op/tokens';

.ds-list--labeled .ds-list-item {
    align-items: normal;
    flex-direction: column;

    // overrides <dt> element style
    &__label {
        color: $opds-color-text-label-default;
        display: flex;
        font-size: $opds-font-size-small;
        font-weight: $opds-font-weight-medium;
        line-height: $opds-line-height-small;
        margin-bottom: $opds-space-xxxs;
        padding-top: 0;
        text-align: left;

        &__text {
            align-items: center;
            display: flex;
            flex-grow: 1;
        }
    }

    &__icon {
        margin-bottom: -$opds-size-icon-default;
    }

    &__icon.ds-icon--large {
        margin-bottom: -$opds-size-icon-large;
    }

    &__content {
        align-items: normal;
        color: $opds-color-text-large;
        flex-basis: auto;
        font-size: $opds-font-size-medium;
    }

    @at-root .ds-list--tiny#{&}__label {
        font-size: $opds-font-size-micro;
        font-weight: $opds-font-weight-bold;
        line-height: $opds-line-height-micro;
    }

    @at-root .ds-list--tiny#{&}__content {
        color: $opds-color-text-default;
        font-size: $opds-font-size-small;
        line-height: $opds-line-height-small;
    }

    @at-root .ds-list--small#{&}__content {
        color: $opds-color-text-default;
        font-size: $opds-font-size-default;
    }

    @at-root .ds-list--large#{&}__label {
        font-size: $opds-font-size-default;
        line-height: $opds-line-height-default;
    }

    @at-root .ds-list--large#{&}__content {
        font-size: $opds-font-size-large;
        line-height: $opds-line-height-large;
    }
}

.ds-list--labeled.ds-list--stacked {
    .ds-list-item__label {
        margin-bottom: $opds-space-xxxs;
    }

    .ds-list-item__content {
        flex-basis: auto;
        padding-left: 0;
    }
}

.ds-list--labeled.ds-list--border-none .ds-list-item {
    align-items: normal;

    &:first-child > :first-of-type,
    > :first-of-type {
        padding-top: 0;
    }

    > :last-of-type {
        padding-bottom: 0;
    }
}

@media only screen and (min-width: $opds-breakpoint-sm-min) {
    .ds-list--labeled:not(.ds-list--stacked) .ds-list-item {
        flex-direction: row;

        &__icon {
            margin-left: 0;
        }

        &__label {
            align-self: baseline;
            flex-basis: 1/3 * 100%;
            justify-content: space-between;
            padding-right: $opds-space-default / 2;
            text-align: right;
        }

        &__label__text {
            justify-content: flex-end;
            position: relative;
            top: ($opds-line-height-default - $opds-line-height-small) / 2;
        }

        dd {
            align-items: normal;
            flex-basis: 2/3 * 100%;
            padding-left: $opds-space-default / 2;
        }

        &--interactive .ds-list-item__content {
            padding-right: $opds-space-xs + $opds-size-icon-default +
                $opds-space-xxxs;
        }

        @at-root .ds-list--border-button#{&} {
            padding-left: $opds-space-small;
            padding-right: $opds-space-small;
        }

        @at-root .ds-list--indented#{&} {
            padding-left: $opds-space-small;
            padding-right: $opds-space-small;
        }

        @at-root .ds-list--indented#{&}--interactive .ds-list-item__content {
            padding-right: $opds-space-xs + $opds-size-icon-default -
                $opds-space-xxs;
        }

        @at-root .ds-list-indented#{&}--interactive .ds-list-item__link__icon {
            padding-right: -$opds-space-xxs + $opds-space-small;
        }

        @at-root .ds-list--tiny#{&}__label__text {
            top: ($opds-line-height-small - $opds-line-height-micro) / 2;
        }

        @at-root .ds-list--large#{&}__label__text {
            top: ($opds-line-height-large - $opds-line-height-default) / 2;
        }

        @at-root .ds-list:not(.ds-list--border-button):not(.ds-list--indented)#{&} {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@media only screen and (min-width: $opds-breakpoint-md-min) {
    .ds-list--indented.ds-list--labeled:not(.ds-list--stacked) .ds-list-item {
        padding-left: $opds-space-default;
        padding-right: $opds-space-default;

        &--interactive .ds-list-item__link__icon {
            padding-right: -$opds-space-xxs + $opds-space-default;
        }
    }
}
