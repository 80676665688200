@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

$size-infobox-infobutton: gu(8);

.ds-form-row {
    margin: 0 0 $opds-space-small 0;
    padding: 0;

    &:first-child {
        margin-top: $opds-space-default;
    }

    &--small {
        margin-bottom: $opds-space-xs;
    }

    &--large,
    &--hero {
        margin-bottom: $opds-space-default;
    }

    &--stacked {
        flex-direction: column;
    }

    &__hint {
        white-space: nowrap;

        #{&}__button {
            margin: ($opds-line-height-small - $size-infobox-infobutton) / 2;
            margin-left: $opds-space-xxxs;
            vertical-align: top;
        }

        &__infobox {
            text-align: left;
            white-space: normal;
        }
    }

    &__item {
        padding-left: 0;
        padding-right: 0;
    }

    &--stacked &__item {
        padding-left: 0;
    }

    &--stacked &__label {
        padding-right: 0;
    }

    &__label {
        color: $opds-color-text-label-default;
        font-size: $opds-font-size-small;
        font-weight: $opds-font-weight-medium;
        line-height: $opds-line-height-small;
        margin-bottom: $opds-space-xxxs;
        padding-left: 0;
        padding-top: 0;
        text-align: left;
    }

    &--disabled &__label {
        color: $opds-color-text-disabled;
        pointer-events: none;
        text-decoration: $opds-text-decoration-disabled;
    }

    &--required &__label::before {
        color: $opds-color-indicator-default;
        content: '* ';
    }

    @media only screen and (min-width: $opds-breakpoint-sm-min) {
        &__item {
            padding-left: gu(3);
        }

        &__label {
            margin-bottom: 0;
            padding-top: (gu(10) - $opds-line-height-small) / 2;
            text-align: right;
        }

        &--small &__label {
            padding-top: (gu(8) - $opds-line-height-small) / 2;
        }

        &--large &__label {
            font-size: $opds-font-size-default;
            line-height: $opds-line-height-default;
            padding-top: (gu(12) - $opds-line-height-default) / 2;
        }

        &--hero &__label {
            font-size: $opds-font-size-default;
            line-height: $opds-line-height-default;
            padding-top: (gu(15) - $opds-line-height-default) / 2;
        }

        &--stacked &__label {
            margin-bottom: $opds-space-xxxs;
            padding-top: 0;
            text-align: left;
        }

        &--large &__label &__hint,
        &--hero &__label &__hint {
            &__button {
                margin: ($opds-line-height-default - $size-infobox-infobutton) /
                    2;
                margin-left: $opds-space-xxxs;
            }
        }
    }
}
