// based on https://github.com/react-component/slider/blob/18c8939/assets/index.less

@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

$height-default: gu(2);
$height-hero: gu(3);

$handle-size-default: gu(6);
$handle-size-large: gu(8);
$handle-size-hero: gu(9);

$handle-vertical-space-small: $opds-space-xxxs;
$handle-vertical-space-default: $opds-space-xxs;
$handle-vertical-space-large: $opds-space-xxs;
$handle-vertical-space-hero: $opds-space-xs;

$rail-vertical-space-small: $opds-space-xxs;
$rail-vertical-space-default: $opds-space-xxs;
$rail-vertical-space-large: $opds-space-xs;
$rail-vertical-space-hero: $opds-space-xs;

.ds-slider {
    $slider: &;

    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    margin: 0 $handle-size-default / 2;
    padding-top: $handle-vertical-space-default;
    position: relative;
    touch-action: none;

    &,
    * {
        // remove tap highlight color for mobile safari
        -webkit-tap-highlight-color: transparent;
    }

    &-rail,
    &-step,
    &-track,
    &-dot {
        height: $height-default;
    }

    &-step,
    &-mark,
    &-rail {
        width: 100%;
    }

    &-mark {
        padding-bottom: $opds-line-height-micro;

        &:empty {
            display: none;
        }
    }

    &-rail,
    &-track {
        border: solid $opds-border-width-default
            $opds-color-border-progressbar-track-default;
        border-bottom-color: $opds-color-border-progressbar-track-bottom;
        border-radius: 50vh;
        margin: $rail-vertical-space-default 0;
    }

    &-rail {
        background-color: $opds-color-background-progressbar-track;
    }

    &-track {
        background-color: $opds-color-indicator-default;
        border-color: $opds-color-indicator-default;
        left: 0;
        position: absolute;
    }

    &-mark-text {
        color: $opds-color-text-secondary;
        cursor: pointer;
        font-size: $opds-font-size-micro;
        font-weight: $opds-font-weight-medium;
        line-height: $opds-line-height-micro;
        position: absolute;
    }

    &-dot {
        background-clip: border-box;
        background-color: $opds-color-palette-grey-500;
        position: absolute;
        width: $opds-border-width-default;
    }

    &-handle {
        box-sizing: content-box;
        height: $handle-size-default;
        padding: $handle-vertical-space-default 0; // To extend the click area beyond the visual handle
        position: absolute;
        top: 0;
        touch-action: pan-x;
        width: $handle-size-default;

        &:focus {
            outline: $opds-width-outline-focus $opds-style-outline-focus
                $opds-color-outline-focus;
        }

        &::after {
            background-color: $opds-color-background-input-default;
            border: solid $opds-size-hairline $opds-color-border-input-default;
            border-radius: 50%;
            content: '';
            display: block;
            height: 100%;
            position: relative;
            width: 100%;
        }

        &:hover::after,
        &:active::after,
        &:focus::after {
            background-color: darken-relative(
                $opds-color-background-input-default,
                $opds-misc-alpha-hover-default
            );
        }

        &:active::after {
            cursor: grabbing;
        }
    }

    &--invalid &-handle {
        &::after {
            background-color: $opds-color-background-input-invalid;
            border-color: $opds-color-border-input-invalid;
            box-shadow: 0 0 gu(1)
                rgba(
                    $opds-color-indicator-error,
                    $opds-misc-alpha-darken-600 / 100%
                );
        }

        &:hover::after,
        &:focus::after {
            background-color: darken-relative(
                $opds-color-background-input-default,
                $opds-misc-alpha-hover-default
            );
            border-color: $opds-color-border-input-default;
            box-shadow: 0 0 gu(2)
                rgba(
                    $opds-color-indicator-error,
                    $opds-misc-alpha-darken-400 / 100%
                );
        }

        &:active::after {
            box-shadow: none;
        }
    }

    &-disabled {
        pointer-events: none;

        #{$slider}-handle::after,
        #{$slider}-dot,
        #{$slider}-mark-text {
            cursor: default;
        }

        #{$slider}-track,
        #{$slider}-dot {
            background-color: $opds-color-icon-disabled;
        }

        #{$slider}-handle::after,
        #{$slider}-rail {
            border-color: $opds-color-border-disabled;
        }

        #{$slider}-track {
            border-color: $opds-color-icon-disabled;
        }

        #{$slider}-mark-text {
            color: $opds-color-text-disabled;
            text-decoration: $opds-text-decoration-disabled;
        }
    }

    &--small {
        padding-top: $handle-vertical-space-small;

        #{$slider}-rail,
        #{$slider}-track {
            margin: $rail-vertical-space-small 0 $handle-vertical-space-small;
        }

        #{$slider}-handle {
            padding: $handle-vertical-space-small 0;
        }
    }

    &--large {
        margin: 0 $handle-size-large / 2;
        padding-top: $handle-vertical-space-large;

        #{$slider}-rail,
        #{$slider}-track {
            margin: $rail-vertical-space-large 0;
        }

        #{$slider}-handle {
            height: $handle-size-large;
            padding: $handle-vertical-space-large 0;
            width: $handle-size-large;
        }
    }

    &--hero {
        margin: 0 $handle-size-hero / 2;
        padding-top: $handle-vertical-space-hero;

        #{$slider}-rail,
        #{$slider}-step,
        #{$slider}-track,
        #{$slider}-dot {
            height: $height-hero;
        }

        #{$slider}-rail,
        #{$slider}-track {
            margin: $rail-vertical-space-hero 0;
        }

        #{$slider}-handle {
            height: $handle-size-hero;
            padding: $handle-vertical-space-hero 0;
            width: $handle-size-hero;
        }
    }
}
